import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import { dhtUserPermissionApi } from '@/api/user.js';

Vue.use(VueRouter);

// 解决编程式路由往同一地址跳转时会报错的情况
// Uncaught (in promise) Error: Navigation cancelled from "/..." to "/..." with a new navigation.
// 重写 push
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
// 重写 replace
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};

// 定义静态路由 一个路由对应一个页面组件
const routes = [
  {
    path: '/',
    name: 'layout',
    redirect: '/home',
    meta: { title: '首页', level: 1 },
    component: () => import("@/views/layout/index.vue"),
  },
  {
    path: '/login',
    name: 'login',
    meta: { title: '登录', level: 1 },
    component: () => import("@/views/login/index.vue")
  },
  {
    path: '/test',
    name: 'test',
    meta: { title: '示例', level: 1 },
    component: () => import("@/views/test/index.vue")
  },
  {
    path: '/404',
    name: 'err_404',
    meta: { title: '404', level: 1 },
    component: () => import("@/views/error/404.vue")
  }
];

// 创建router实例 注册路由
const router = new VueRouter({
  mode: 'history',
  routes
});

// 路由重置
export function resetRouter() {
  router.matcher = new VueRouter({
    mode: 'history',
    routes
  }).matcher;
}
// 动态路由
let asyncRoutes = [];
// 首页路由
let homeRoute = {
  path: '/home',
  name: 'home',
  meta: { title: '控制台', level: 2, pname: 'layout' },
  component: () => import("@/views/home/index.vue")
};
const loginPath = '/login';
const homePath = '/home';

// 递归节点树注册路由
function flattenToAddRoute(pname, item) {
  if (item.level < 3) {
    // let path = item.level == 1 ? '/' + item.url : item.url;
    let path = item.url;
    // 页面按钮权限
    let permissions = [];
    if (item.level == 2) {
      item.children.forEach((d) => {
        permissions.push(d.name);
      });
    }
    if (!item.component) {
      return false;
    }
    let route = {
      path,
      name: item.name,
      component: () => import(`@/views/${item.component}.vue`),
      meta: { title: item.title, level: item.level + 1, permissions, pname }
    };
    if (item.redirect) {
      if (!store.state.routeUrls[item.redirect]) {
        route.redirect = item.children[0].url || '';
      } else {
        route.redirect = item.redirect;
      }
      // let arr = item.redirect.split('/');
      // arr[0] === '' && arr.shift();
      // if (!store.state.routeUrls[arr[1]]) {
      //   arr[1] = item.children && item.children.length > 0 ? item.children[0].url : '';
      //   route.redirect = '/' + arr.filter(d => d).join('/');
      // } else {
      //   route.redirect = item.redirect;
      // }
    }
    asyncRoutes.push(route);
    router.addRoute(pname, route);
    if (item.level == 1) {
      item.children.forEach((item2) => {
        flattenToAddRoute(item.name, item2);
      });
    }
  }
}
// 检查路由是否在白名单中 返回bool值
function inRouteWhiteList(path) {
  return Number([
    '/login'
  ].indexOf(path)) > -1;
}

router.beforeEach(async (to, from, next) => {
  const token = store.getters.token;
  const storeRoutes = store.getters.routes;
  // const routerRoutes = router.getRoutes();

  // console.log('storeRoutes: %s, routerRoutes: %s',storeRoutes.length, routerRoutes.length - routes.length);

  // 更新页面的操作权限
  if (to.meta.level == 3) {
    store.commit('permission/setActions', to.meta.permissions || []);
  }

  if (!token && !inRouteWhiteList(to.path)) {
    store.commit('logout');
    next({ path: loginPath });
    return false;
  }
  if (token && inRouteWhiteList(to.path)) {
    next({ path: homePath });
    return false;
  }
  if (!storeRoutes.length && !inRouteWhiteList(to.path)) {
    // 注册首页路由
    asyncRoutes = [homeRoute];
    router.addRoute('layout', homeRoute);
    // 注册从接口返回的路由
    let res = await dhtUserPermissionApi();
    if (res && res.data.access_list.length) {
      store.commit('setRouteUrls', res.data.route_url_list || []);
      let accessList = res.data.access_list || [];
      accessList.forEach((item) => {
        flattenToAddRoute('layout', item);
      });
    }
    store.commit('setRoutes', asyncRoutes);
    // 遍历菜单列表
    let navMenuList = [];
    let navMenuTitle = {};
    asyncRoutes.forEach((d) => {
      navMenuTitle[d.name] = d.meta.title;
      if (d.name === 'home') {
        return false;
      }
      if (d.meta.level == 2) {
        let menuItem = {
          title: d.meta.title,
          index: d.path,
          level: d.meta.level,
          children: []
        };
        asyncRoutes.forEach((dd) => {
          if (dd.meta.pname === d.name) {
            menuItem.children.push({
              title: dd.meta.title,
              index: dd.path,
              level: dd.meta.level
            });
          }
        });
        navMenuList.push(menuItem);
      }
    });
    // 更新菜单列表
    store.commit('navMenu/navMenuList', { navMenuList });
    // 更新面包屑
    store.commit('setBreadcrumbList', to);
    next({ path: to.path });
    return false;
  }
  // 更新选中的菜单
  store.commit('navMenu/navMenuChange', {
    level: to.meta.level,
    path: to.path
  });
  // 更新面包屑
  store.commit('setBreadcrumbList', to);

  next();
});

export default router;