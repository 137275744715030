import axios from "axios";
import router from "@/router";
import { Message } from "element-ui";
import store from "@/store";

const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 30000
});

// 请求拦截器
request.interceptors.request.use(function (config) {
  const token = store.getters.token;
  if (token) {
    config.headers.Authorization = 'Bearer ' + token;
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

// 响应拦截器
request.interceptors.response.use(function (response) {
  let res = response.data;
  const codeArr = [4011, 4012, 4013, 4014, 4015, 4016];
  if (codeArr.indexOf(res.code) !== -1) {
    Message({
      type: 'warning',
      message: res.msg || '身份校验失败',
      offset: '100'
    });
    store.commit('logout');
    return false;
  }
  if (res.code != 200) {
    Message({
      type: 'warning',
      message: res.msg || '网络错误',
      offset: '100'
    });
    return false;
  }
  return res;
}, function (error) {
  if (error.code === 'ECONNABORTED' || error.message.includes("timeout")) {
    Message({
      type: 'warning',
      message: '操作超时',
      offset: '100'
    });
    return false;
  }
  return Promise.reject(error);
});

export default request;